.MuiDialog-paperWidthSm,
.MuiDialog-paperWidthXs {
  width: 90%;
  max-width: 290px !important;
}

.MuiDialog-paper {
  margin: 0 !important;
}

.MuiDialogContent-root {
  padding: 18px 0 18px 0 !important;
}
