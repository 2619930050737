body {
  font-family: "Montserrat", sans-serif;
}

.header {
  /* text-align: center; */
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1;
}

*:focus {
  outline: none !important;
}
