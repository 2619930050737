.MuiMobileStepper-root {
  padding: 8px 24px !important;
  background: unset !important;
}

.MuiMobileStepper-dot {
  width: 12px !important;
  height: 12px !important;
  margin: 0 4px !important;
}

.MuiMobileStepper-dotActive {
  background-color: #13c0dd !important;
}

.MuiMobileStepper-root .MuiSvgIcon-root {
  font-size: 2.5rem;
}

/* #bingo.MuiButton-root:hover, #bingo.MuiButton-contained:hover {
  background-color: royalblue!important;
} */
