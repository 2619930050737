.MuiTabs-flexContainer {
  height: 50px;
}

.MuiTab-labelIcon {
  min-height: 0!important;
  padding-top: 3px!important;
}

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0!important;
}

.MuiTab-root {
  text-transform: unset!important;
}

@media (min-width: 500px) {
  .logo-image {
    padding-left: 15px!important;
  }
}