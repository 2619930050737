.MuiDialogContent-root {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: white !important;
}

.MuiPickersDatePickerRoot-toolbar {
  background-color: #183d37 !important;
}

.MuiPickersDay-daySelected {
  background-color: #183d37 !important;
  color: white !important;
}

.MuiDialogActions-root .MuiButton-label {
  color: black;
}
